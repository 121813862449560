import { apiGet, apiPost } from './service'

export async function listAdmin(data) {
	return await apiPost("/api/Admin/list", data);
}

export async function fullListAdmin(data) {
	return await apiPost("/api/Admin/fulllist", data);
}

export function getAdmin(data) {
	let url = "/api/Admin/read/" + data;
	return apiGet(url, {params: {timestamp: Math.random()}});
}

export function postAdmin(data) {
	return apiPost("/api/Admin/edit", data);
}

export function addAdmin(data) {
	return apiPost("/api/Admin/add", data);
}

export function deleteAdmin(data) {
	return apiGet("/api/Admin/delete/" + data);
}
